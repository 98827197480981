"use client";

import { useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";
import { Button, Heading, Text, Link, ImageWrapper } from "ui-components";

import { useCurrentLocale } from "@/src/hooks/useCurrentLocale";

export default function NotFound(): JSX.Element {
  const { t } = useTranslation("not-found");
  const router = useRouter();
  const currentLocal = useCurrentLocale();

  return (
    <div className="flex flex-col justify-center items-center gap-5 my-10">
      <Heading className="text-center px-5">
        {t("sorry_we_could_not_find_that_page")}
      </Heading>
      <div>
        <Text>{t("try_searching_or_go_to")}&nbsp;</Text>
        <Link href="/">{t("myGwork_home_page")}</Link>
      </div>
      <Button onClick={() => router.push(`/${currentLocal}/`)}>
        {t("visit_homepage")}
      </Button>
      <ImageWrapper
        src="/not-found.png"
        width={555}
        height={500}
        alt="Picture of the author"
      />
    </div>
  );
}
